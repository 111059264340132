import React, { useEffect } from "react";

const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window?.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows-phone";
  }
  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
    return "ios";
  }

  return "unknown";
};

function App() {
  useEffect(() => {
    const path = window.location.pathname?.slice(1);
    if (!!path.length) {
      window.location.href = `superpe://${path}`;
      // window.open(`superpe://${path}`, "_blank");
      // window.location.replace(`superpe://${path}`);
    }

    const os = getOS();
    console.log(os);

    if (os === "android") {
      console.log("opening playstore");
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.mewt.app";
      // window.open(
      //   "https://play.google.com/store/apps/details?id=com.mewt.app",
      //   "_blank"
      // );
      // window.location.replace(
      //   "https://play.google.com/store/apps/details?id=com.mewt.app"
      // );
    } else if (os === "ios") {
      console.log("opening appstore");
      window.location.href =
        "https://apps.apple.com/in/app/superpe/id6471577933";
      // window.open(
      //   "https://apps.apple.com/in/app/superpe/id6471577933",
      //   "_blank"
      // );
      // window.location.replace(
      //   "https://apps.apple.com/in/app/superpe/id6471577933"
      // );
    }
    // else {
    //   window.href = "https://superpe.in";
    // }

    setTimeout(() => {
      window.location.href = "https://superpe.in";
    }, 2000);
    // window.open("https://superpe.in", "_self", "");
    // window.location.replace("https://superpe.in");
  }, []);

  return <div className="App"></div>;
}

export default App;
